<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar" />
      <Navbar slot="navbar" />
      <Footer slot="footer" />
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
        </div>-->
        <div class="pre-loader text-center" v-if="preLoader">
          <span class="loader"></span>
        </div>

<div style="max-width:700px; margin:4% 5%"     v-if="windowWidth < 768">
   
      <h4 style="font-size:24px !important">  <img src="../../../assets/images/back_mob.png" loading="lazy" alt="" height="18" 
       class="mb-2 " @click="goBack"> Investment </h4>
      <h4 class="mt-1 " style="font-size:24px !important"> <img src="../../../assets/images/back_mob.png" loading="lazy" alt="" height="18" 
       class="mb-2 " style="opacity:0" @click="goBack">  Summary</h4>
    
   
</div>
        <div class="invest-container" v-if="!preLoader">
          <div style="padding:20px" v-if="windowWidth > 767">
       

               <img @click="goBack" class="point-cursor"
                      src="../../../assets/images/back1.png"
                      height="50">
          </div>

          <div style="max-width:600px; margin:0 auto">
            <h4 class="mt-4 mb-4" v-if="windowWidth > 767">Investment Summary</h4>

            <div
              style="min-height:40px ;display:flex; justify-content:space-between;"
              class="mt-4"
            >
              <div style="flex-basis:50%" class="mr-4">
                <h5 style="font-size:18px; color:#269696">
                {{dollarProduct.name}}
                </h5>
                <!-- <p class="pt-2" style="color:#828282">Imagine Africa</p> -->
              </div>
              <div
                style="flex-basis:50%; display:flex;;justify-content:flex-end; flex-wrap:wrap "
                v-if="windowWidth > 767"
              >
                <p style="color:#4F4F4F" class="pt-2">{{serverTime | dateSlashTime}}</p>
              </div>
            </div>


            <div
              style="min-height:40px ;display:flex; justify-content:space-between; border-bottom:1px solid #E0E0E0;"
              class="mt-4"
            >
              <div style="flex-basis:50%" class="mr-4">
                <h5 style="font-size:14px;">
                Investment
                </h5>
              </div>
              <div
                style="flex-basis:50%; display:flex;;justify-content:flex-end; flex-wrap:wrap "
              >
                <p style="color:#4F4F4F">${{formatAmount(bondData.amountInUsd)}}</p>
              </div>
            </div>
            <div
              style="min-height:40px ;display:flex; justify-content:space-between; border-bottom:1px solid #E0E0E0;"
              class="mt-4"
            >
              <div style="flex-basis:50%" class="mr-4">
                <h5 style="font-size:14px;">
             Naira Value
                </h5>
              </div>
              <div
                style="flex-basis:50%; display:flex;;justify-content:flex-end; flex-wrap:wrap "
              >
                <p style="color:#4F4F4F" >  <span>&#8358;</span>{{formatAmount(bondData.nairaEquiv)}}</p>
              </div>
            </div>
            <div
              style="min-height:40px ;display:flex; justify-content:space-between; border-bottom:1px solid #E0E0E0;"
              class="mt-4"
            >
              <div style="flex-basis:50%" class="mr-4">
                <h5 style="font-size:14px;">
Maturity
                </h5>
              </div>
              <div
                style="flex-basis:50%; display:flex;;align-items:flex-end; flex-direction:column"
              >
                <p style="color:#4F4F4F" ><b>{{dollarBondMaturityDate.maturity_date | momentFormatDayMonthYear}} </b></p>
                <p style="color:#4F4F4F" >{{dollarBondMaturityDate.numOfDays}} Days ({{dollarBondMaturityDate.numOfMonths}} Months)</p>
              </div>
            </div>
            <div
              style="min-height:40px ;display:flex; justify-content:space-between; border-bottom:1px solid #E0E0E0;"
              class="mt-4"
            >
              <div style="flex-basis:50%" class="mr-4">
                <h5 style="font-size:14px;">
Processing Fee ({{dollarBondData.eubond_processing_rate}}%)
                </h5>
              </div>
              <div
                style="flex-basis:50%; display:flex;;align-items:flex-end; flex-direction:column"
              >
                <p style="color:#4F4F4F"><span>&#8358;</span>{{formatAmount(bondData.percentValueInNaira)}}</p>
              </div>
            </div>
            <div
              style="min-height:40px ;display:flex; justify-content:space-between; border-bottom:1px solid #E0E0E0;"
              class="mt-4"
            >
              <div style="flex-basis:50%" class="mr-4">
                <h5 style="font-size:14px;">
Total Payment
                </h5>
              </div>
              <div
                style="flex-basis:50%; display:flex;;align-items:flex-end; flex-direction:column"
              >
                <p style="color:#4F4F4F"><span>&#8358;</span>{{formatAmount(bondData.totalBalanceToBePaid)}}</p>
              </div>
            </div>
            <div
              style="min-height:40px ;display:flex; justify-content:space-between;"
              class="mt-4"
            >
            
              <div
                style="flex-basis:100%; display:flex;;align-items:flex-end; flex-direction:column"
              >
               <button class="inv-btn" @click="purchaseBond">
Complete Purchase  <span style="font-size:16px">   <img
                      src="../../../assets/images/chevright.png"
                      height="15"
                 class="pl-2"
                    > </span>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";
import http from "../../../http/index";
import api from "../../../apis/index";
export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
      minAmount: "",
     
    };
  },
  methods: {
  
    goBack() {
      this.$router.push({ path: "/dollar/tenor" });
    },

    purchaseBond() {


let bondProductData = this.primaryMarket.filter((data)=>{

if(data.asset_tag.toLowerCase() == 'euro_bond'){

  return data
}
})

// console.log(bondProductData)
// return
      let bondData = {
        amount_usd: this.bondData.amountInUsd,
        product_id: bondProductData[0].id,
        maturity_date_id: this.dollarBondMaturityDate.id,
        tag_id: this.bondData.tagSelected,
      };
      if(bondData.tag_id == ''){
                delete bondData.tag_id
      }

      let vm = this;
      vm.$store.commit("market/startPreloader");

      //   this.$helpers.log(amountData, 'getAmount')
      http
        .post(api.purchaseEuroBond, bondData)

        .then((response) => {
          vm.$store.commit("market/stopPreloader");

          this.$router.push({
            path: "/dollar/success",
            query: { fundAmount: this.bondData.amountInUsd },
          });
          vm.$helpers.log(response);
        })

        .catch((error) => {
          if (error.response) {
            if (error.response.data.message !== "Unauthenticated.") {
              vm.$store.commit("domElements/openNotificationMessage");

              vm.$store.commit(
                "domElements/saveNotificationMessage",
                error.response.data.message
              );
              vm.$helpers.log(error.response);
              vm.$store.commit("market/stopPreloader");
            }
          }
          this.$store.commit("market/stopLoader");
        });
    },
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper,
  },

  computed: {
    ...mapState({
      userBal: (state) => state.auth.userProfileInfo.balance,
        primaryMarket: state => state.market.primaryMarket,
      dollarBondMaturityArray: (state) =>
        state.market.dollarBondMaturityArray,
      dollarBondMaturityDate: (state) =>
        state.market.dollarBondMaturityDate,
  
      bondData: (state) =>
        state.market.bondData,
     
      dollarBondData: (state) =>
        state.market.dollarBondData,
      dollarBondProductId: (state) =>
        state.market.dollarBondProductId,
      serverTime: (state) =>
        state.market.serverTime,
    }),
 
    
  },
  mounted() {
    let heading = {
           main: this.tran.euro_bond
    };

     if(this.bondData == null){
        this.$router.push({
            path: "/dollar/invest",
         
          });
    }
        this.$store.commit("market/savePageHeading", heading);
  },
};
</script>

<style scoped>
.invest-container {
  background: #fff;
  min-height: 80vh;
  margin: 5%;
  margin-top: 2%;
  padding: 10px;
  border-radius: 10px;
}

.tenor-div {
  cursor: pointer;
  transition: 0.4s;
}

.grey-text {
  color: #828282;
}

.inv-btn{
  background:#0094FF; border:1px solid #fff; border-radius:5px; min-height:55px; min-width:130px; color:#fff;
    padding: 10px 20px;
}

@media (max-width: 1500px) {
  .invest-container {
    zoom: 85%;
    min-height: 90vh;
  }
}
@media (max-width: 767px) {
  .inv-btn {
    min-width:100%;
  }
  .invest-container{
        min-height: 50vh;
}
}
</style>
