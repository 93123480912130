<template>
<div>
    <DollarInvestmentSummary />
</div>
</template>

<script>
import DollarInvestmentSummary from '../../components/dashboard/dollarAsset/DollarInvestmentSummary.vue'
export default {
    components: {
        DollarInvestmentSummary
    }
}
</script>

